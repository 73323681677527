import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { object, string } from 'yup';

import { Box, TextField, Button, Snackbar, Alert, Typography } from '@mui/material';

import { LoginLayout } from '@components/layout/LoginLayout';
import { useAuth } from '@context/auth';

function ForgotPassword() {
  const [value, setValue] = useState('');
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const schema = object({
    email: string().lowercase().trim().required('E-mail inválido').email('E-mail inválido'),
  });

  const auth = useAuth();

  const changeEmail = (e) => {
    setValue(e.target.value);

    if (error) setError(null);
  };

  const send = async () => {
    try {
      setLoading(true);

      const validate = await schema.validate({ email: value }, { abortEarly: false });

      const result = await auth?.forgotPassword(validate.email);

      if (result.error) {
        setLoading(false);

        if (result.code === 'auth/user-not-found') {
          navigate('/needVerifyPassError');
          return;
        }

        // setOpen(true);

        return;
      }

      navigate('/needVerifyPass');
    } catch (err) {
      setLoading(false);

      const errs = {};
      err.inner.forEach((item) => (errs[item.path] = item.message));

      setError(errs.email);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <LoginLayout>
      <Box
        sx={{
          width: 1,
          height: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
        }}
      >
        <Box
          sx={{ width: '100%', textAlign: 'center', borderBottom: '1px solid var(--gray-3)', mb: '6rem', pb: '2rem' }}
        >
          <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
            Esqueceu sua senha?
          </Typography>
          <Typography variante="body1">Enviaremos a recuperação por e-mail</Typography>
        </Box>
        <TextField
          name="email"
          label="e-mail"
          value={value}
          fullWidth
          sx={{ maxWidth: '600px' }}
          onChange={changeEmail}
          helperText={error ?? ' '}
          error={Boolean(error)}
        />
        <Box sx={{ mt: '1.5rem', display: 'flex', gap: '1rem' }}>
          <Button variant="contained" onClick={() => navigate('/login')} disabled={loading}>
            voltar
          </Button>
          <Button variant="contained" onClick={send} disabled={loading}>
            enviar
          </Button>
        </Box>
      </Box>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
          Erro no envio, por favor tente mais tarde
        </Alert>
      </Snackbar>
    </LoginLayout>
  );
}

export default ForgotPassword;
